import React, { useRef } from 'react';
import menu from '../config/main_nav';
import { Link, useLocation } from "react-router-dom";

export default function Nav(props) {
  let location = useLocation();
  const mobMenu = useRef(null);

  function toggleMenu() {
    mobMenu.current.classList.toggle('open');
  }


  return (
    <header className="header">
      <div></div>
      <div className="grid-container full">
        <div className="grid-x">
          <div className="cell">

            <div className="main-navigation-top relative">
              <div className="grid-container">
                <div className="grid-x grid-padding-x grid-padding-y align-middle">

                  <div className="cell auto">
                    <div className="logo-header">
                      <Link to={menu.trails.link}>
                        <img src="/images/salesforce.svg" alt="Salesforce"/>
                        {/* <img src={props.settings.image} alt={props.settings.name}/> */}
                      </Link>
                    </div>
                  </div>
                  <div className="cell shrink">
                    <div className="desktop-menu show-for-medium">
                      <nav className="main-menu">
                        <ul className="menu align-right">
                    <li className={location.pathname === menu.trails.link ? "active" : ''}><Link className="big-link" to={menu.trails.link}>{menu.trails.caption}</Link></li>
                    <li className={[location.pathname === menu.sfplus.link ? "active" : '', props.settings.hide_sfplus === false && props.settings.sfplus.length > 0 ? '' : 'hidden'].join(' ')}><Link  to={menu.sfplus.link}>{menu.sfplus.caption}</Link></li>
                    <li className={[location.pathname === menu.connect.link ? "active" : '', props.settings.hide_community === false && props.settings.community.length > 0 ? '' : 'hidden'].join(' ')}><Link to={menu.connect.link}>{menu.connect.caption}</Link></li>
                    {/* <li className="nav-divider"><div className="divider">|</div></li> */}
                    <li  className={[location.pathname === menu.rewards.link ? "active" : '', props.settings.hide_rewards === false ? '' : 'hidden'].join(' ')}><Link to={menu.rewards.link}>{menu.rewards.caption}</Link></li>
                    <li className={[location.pathname === menu.team.link ? "active" : '', props.settings.hide_support === false && props.settings.support.length > 0 || props.settings.members.length > 0 ? '' : 'hidden'].join(' ')}>
                      <Link  to={menu.team.link}>{menu.team.caption}</Link>
                    </li>
                    <li className={[location.pathname === menu.nextsteps.link ? "active" : '', props.settings.hide_nextsteps === false  ? '' : 'hidden'].join(' ')}>
                      <Link  to={menu.nextsteps.link}>{menu.nextsteps.caption}</Link>
                    </li>
                      {/* <li>
                        <a href="https://www.trailhead.com" target="_blank" rel="noopener noreferrer">Trailhead</a>
                      </li> */}
                      </ul>
                      </nav>
                    </div>
                    <div className="menu-button hide-for-medium" aria-hidden="true">
                      <a href="#"  className="open-menu" onClick={toggleMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-navigation">
              <div className="grid-container">
                <div className="grid-x grid-padding-x">
                  <div className="cell">

                    <div ref={mobMenu} className="mobile-menu" id="mobile-menu" aria-hidden="true">
                      <div className="menu-button" aria-hidden="true" onClick={toggleMenu}>
                        <a href="#" className="open-menu">
                          <span></span>
                          <span></span>
                          <span></span>
                        </a>
                      </div>
                      <nav className="overlay-menu" role="navigation">
                        <ul className="main-menu align-center">
                    <li><Link className={location.pathname === menu.trails.link ? "active" : ''} to="/" onClick={location.pathname === '/' ? toggleMenu : () => null}>{menu.trails.caption}</Link></li>
                    <br/>
                    <li><Link className={[location.pathname === menu.sfplus.link ? "active" : '', props.settings.hide_sfplus === false && props.settings.sfplus.length > 0 ? '' : 'hidden'].join(' ')} to={menu.sfplus.link} onClick={location.pathname === menu.sfplus.link ? toggleMenu : ()=>null }>{menu.sfplus.caption}</Link></li>
                    <li><Link className={[location.pathname === menu.connect.link ? "active" : '', props.settings.hide_community === false && props.settings.community.length > 0 ? '' : 'hidden'].join(' ')} to={menu.connect.link} onClick={location.pathname === menu.connect.link ? toggleMenu : ()=>null }>{menu.connect.caption}</Link></li>
                    <li><Link className={[location.pathname === menu.rewards.link ? "active" : '', props.settings.hide_rewards === false ? '' : 'hidden'].join(' ')} to={menu.rewards.link} onClick={location.pathname === menu.rewards.link ? toggleMenu : ()=>null }>{menu.rewards.caption}</Link></li>
                    <li><Link className={[location.pathname === menu.team.link ? "active" : '', props.settings.hide_support === false && props.settings.support.length > 0 || props.settings.members.length > 0 ? '' : 'hidden'].join(' ')} to={menu.team.link} onClick={location.pathname === menu.team.link ? toggleMenu : ()=>null }>{menu.team.caption}</Link></li>
                    <li><Link className={[location.pathname === menu.nextsteps.link ? "active" : '', props.settings.hide_nextsteps === false  ? '' : 'hidden'].join(' ')} to={menu.nextsteps.link} onClick={location.pathname === menu.nextsteps.link ? toggleMenu : ()=>null }>{menu.nextsteps.caption}</Link></li>
                    {/* <li> */}
                        {/* <a href="https://www.trailhead.com" target="_blank" rel="noopener noreferrer">Trailhead</a> */}
                      {/* </li> */}
                    </ul>
                      </nav>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </header>
  )};