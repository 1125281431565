import "./rewards_page.scss";
import React, { useEffect } from "react";
// import Equalizer from "react-equalizer";
import Nav from "../nav.js";
// import ReactGA from "react-ga";
import uuid from "react-uuid";
import Badge from "./badge";
import BadgesData from "../../config/badges_data";
import Prize from "./prize";
// import { useSpring, animated } from "react-spring";
import LegalFooter from "../legal_footer/legal_footer";
export default function RewardsPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  // const currentTheme = 2;
  const topBg = "images/rewards_page/rewarded-top-2.png";
  const botBg = "images/rewards_page/rewarded-bottom-2.png";
  const centerBg = "images/rewards_page/rewarded-center-2.png";
  const sectionTopBgStyle = { backgroundImage: "url(" + topBg + ")" };
  const sectionBotBgStyle = { backgroundImage: "url(" + botBg + ")" };
  const sectionCenterBgStyle = { backgroundImage: "url(" + centerBg + ")" };
  // ReactGA.event({
  //   category: "Rewards",
  //   action: "page visited",
  // });

  return (
    <>
      <Nav settings={props.settings} />

      <section
        id="rewards"
        className={[
          "rewards section section-gap-1  text-center",
          "section-bg-0",
        ].join(" ")}
        style={sectionCenterBgStyle}
      >
        <div className="background-image-top" style={sectionTopBgStyle}></div>
        <div
          className="background-image-bottom"
          style={sectionBotBgStyle}
        ></div>
        <div className="grid-container">
          <div className="grid-x">
            <div className="cell">
              <div className="section-container">
                <h1 className="section-title">Rewards</h1>
                <h2 className="section-subtitle">
                  The further you go, the more you earn.
                </h2>
                <div className="section-description prizes-description">
                  {props.settings.reward_text_1}
                  {/* Prizes will be determined by the top achievements and rewarded at the end of the challenge. */}
                  {/* <br/> Badges/Points earned previous to the Challenge will not count towards eligibility for the prizes. 
                  Points will be used as tie-breakers.
                  <br/>
                  <br/> */}
                  {/* <p>
                  All prizes are below the legal value limit (35 EUR) for third-party gratuities and can be accepted. CD 3002 “Gratuities in dealings with third parties” must be observed, as well as other GB specific regulations where applicable. 
                  </p> */}
                  {/* <p> We are going to take a snapshot of your current points and badges at the beginning of our Challenge, 
                    and compare that after two months to see who made the most progress.
                    <br />Leaderboard will be posted regularly via the SPAS Community of Practice channel on Teams.
                </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rewards-prize--container">
          <div className="grid-container">
            <div className="grid-x align-center align-stretch">
              {Object.keys(props.settings.rewards).map((t) => (
                <Prize
                  key={uuid()}
                  cardTitle={t}
                  header={props.settings.rewards[t].heading}
                  subtitle={props.settings.rewards[t].subtitle}
                  color={props.settings.rewards[t].color}
                  description={props.settings.rewards[t].name}
                  image={props.settings.rewards[t].image}
                  price={props.settings.rewards[t].price}
                  option1_name={props.settings.rewards[t].option1_name}
                  option1_link={props.settings.rewards[t].option1_link}
                  option2_name={props.settings.rewards[t].option2_name}
                  option2_link={props.settings.rewards[t].option2_link}
                  claimLink={props.settings.rewards[t].permalink}
                  altEmea={props.settings.rewards[t].emea}
                />
              ))}
            </div>
          </div>
          {/* <div className="grid-container">
          <br/>
          <br/>
          <h3 className='text-center z-99'>Everyone who attends the Trailhead Challenge kickoff meeting:</h3>
          <div className="grid-x align-center align-stretch">
              <Prize
                key={uuid()}
                cardTitle={props.settings.bonusReward.header}
                header={props.settings.bonusReward.header}
                subtitle={props.settings.bonusReward.subtitle}
                color={props.settings.bonusReward.color}
                description={''}
                image={props.settings.bonusReward.image}
                price={''}
                claimLink={''}
                altEmea={''}
              />
          </div>
        </div> */}
        </div>
        <br />
        <div className="margin-top-30 grid-container">
          <div className="grid-x">
            <div className="cell">
              <div className="section-container">
                <h1 className="section-title">Climb the Ranks</h1>
                <p className="section-description bold align-center">
                  Trailblazer Ranks indicate your progress on your Trailhead learning journey. With every module you complete, you’ll earn a badge and points that contribute toward your Trailblazer Rank.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="rewards-card--container">
          <div className="grid-container">
            <div className="grid-x align-center align-top">
              {Object.keys(BadgesData).map((t) => (
                <Badge
                  key={uuid()}
                  cardTitle={t}
                  header={BadgesData[t].header}
                  badges={BadgesData[t].badges}
                  points={BadgesData[t].points}
                  image={BadgesData[t].image}
                  link={BadgesData[t].link}
                />
              ))}
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <LegalFooter
          consentOpen={props.consentOpen}
          setConsentOpen={props.setConsentOpen}
        />
      </section>
    </>
  );
}
