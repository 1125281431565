import React from 'react';
import './trails.scss';
import Trail from './trail';
import uuid from 'react-uuid'
import Loading from "../loading/loading_icon";

export default function TrailList(props) {
  if (props.trailsLoading === false && props.currentChest) {
    return (
      <>
        <div className="trails-card--middle" >
          {/* <h3 className="trail--title">{props.currentChest.title}</h3> */}

          <div className="trails-card--block">
            <div className={props.currentChest.permalink !== "" ? "learn-buttons center" : "learn-buttons center hidden"}>
              <a href={props.currentChest.permalink} target="_blank" rel="noopener noreferrer" className="main-button button learn-button">
                {props.currentChest.trails.length > 0 ? 'Learn these skills' : 'Learn this skill'}
              </a>
            </div>
            { props.currentChest.trails && props.currentChest.trails.length > 0 ?
            Object.keys(props.currentChest.trails).map((t) => (
              <Trail
                key={uuid()}
                trailData={props.currentChest.trails[t]}
                trailIndex={t}
              />
            )) : ''}
          </div>
        </div>
      </>

    )
  } else {
    return (
      <>
        <div className="trails-card--middle relative" >
          <Loading />
        </div>
      </>
    )
  }
};
