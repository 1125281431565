import React from 'react';
import './topics.scss';
// import './topics_page.scss';
import TopicList from './topic_list.js'
export default function Topics(props) {

  // const corpColorStyle = { color: props.primaryColor };
  if (props.settings.trail_groups && props.settings.trail_groups.length > 0) {
  return (
    <section id="topics-section" className={[props.currentSlide > 1 ? 'visible' : 'hidden', props.currentSlide === 3 ? 'active' : '', 'topics section section-bg-2 text-center'].join(' ')}>
      <div className="grid-container fluid">
        <div className="grid-x align-center align-top full-height">
          <div className="cell">
            <div className="section-container section-gap-2">
              <div className="grid-container relative z-index-1">
                <div className="grid-x align-center">
                  <div className="cell small-12 medium-7 large-8 topic-cell" >
                    <div className="section-tag">01/03</div>
                    <h2 className="section-title">Which skills would <em>you</em> like to unlock?</h2>
                    <h3 className="section-subtitle topics-section-subtitle text-glow">Explore skills that align with your role<br/> or challenge yourself outside of your areas of expertise.</h3>
                  </div>
                </div>
              </div>
              <TopicList
                topics={props.topics}
                topicsLoading={props.topicsLoading}
                currentTopic={props.currentTopic}
                setTopic={props.setTopic}
                settings={props.settings}
                topicChosen={props.topicChosen}
              >
              </TopicList>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
  }else{
    return true
  }
};
