import React from 'react';
// import {ReactComponent as ChestImg} from './chest.svg'
// let chestCanOpen = true
export default function Chest(props){
  // const theme = 2;
  const timeOut = 1;
  var chestImgPath = ""
  if (props.chestData.image_url) {
    chestImgPath = props.chestData.image_url
  }
  const chestImg = { backgroundImage: 'url(' + chestImgPath + ')' };
  function openChest(e) {
      props.chestData.isOpened = true;
      e.target.parentElement.classList.add("open");
      setTimeout(function() {
        props.setChest(props.chestIndex)
      }, timeOut);
    // };
  }
  function visitChest(e, url) {
    props.chestData.isOpened = true;
    if (e.target.classList.contains("mixcard")) {
      e.target.firstChild.firstChild.classList.remove("hidden");
    }else{
      e.target.parentElement.closest(".mixcard").firstChild.firstChild.classList.remove("hidden");
    }
    e.target.parentElement.classList.add("open");
    setTimeout(function() {
      // props.setSuperBadge(props.chestIndex)
      window.open(url, '_blank');
    }, timeOut);
  // };
}
    return(
      <>
    <div className="cell small-6 medium-4 large-4 xlarge-3 xxlarge-3 xxxlarge-3">
    <div className="mixcard" onClick={(e) => props.isList ? openChest(e) : visitChest(e, props.chestData.permalink) }>
      <div className={["mixcard__image__wrap", props.chestData.theme && props.chestData.theme !== '' ? props.chestData.theme : 'no-theme'].join(' ')}>
        <div className={["checkmark", props.chestData.isOpened ? '' : 'hidden'].join(' ')}></div>
        <div className="mixcard__image" style={chestImg} alt={props.chestData.title} />
      </div>
      <h4 className="mixcard__title">
      {props.chestData.name}
      </h4>
        <div className="mixcard__description">{props.chestData.subtitle}</div>
      </div>
    </div>
</>
    )
};
