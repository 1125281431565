export default { items: [
  {
      "name": "Trailhead",
      "description": "Keep skilling up for the future with Trailhead.",
      "permalink": "https://trailhead.salesforce.com/today",
      "image": "../../images/nextsteps_page/img1.png",
      "ctaText": "Explore Trailhead"
  },

{
    "name": "Expert-Led Classes",
    "description": "Learn directly from Salesforce experts.",
    "permalink": "https://trailheadacademy.salesforce.com/classes",
    "image": "../../images/nextsteps_page/img2.png",
    "ctaText": "Explore classes"
},
  {
      "name": "Salesforce Credentials",
      "description": "Prove your hands-on experience and highlight your skills.",
      "permalink": "https://trailhead.salesforce.com/en/credentials/administratoroverview/",
      "image": "../../images/nextsteps_page/img3.png",
      "ctaText": "Explore Certifications"
  }  
]
}