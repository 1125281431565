import React from 'react';
// import {ReactComponent as TopicImg} from './topic.svg'
// let topicCanOpen = true
export default function Topic(props){
  // const theme = 2;
  const timeOut = 1;
  // var topicImgPath = ""
  var topicImgPath = props.topicData.image ? props.topicData.image : "../../images/noavatar.png";
  // if (props.topicData.image) {
  //   topicImgPath = props.topicData.image
  // }
  const topicImg = { backgroundImage: 'url(' + topicImgPath + ')' };
  function openTopic(e) {
      props.topicData.isOpened = true;
      e.target.parentElement.classList.add("open");
      setTimeout(function() {
        props.setTopic(props.topicIndex)
      }, timeOut);
    // };
  }
  function visitTopic(e, url) {
    props.topicData.isOpened = true;
    if (e.target.classList.contains("mixcard")) {
      e.target.firstChild.firstChild.classList.remove("hidden");
    }else{
      e.target.parentElement.closest(".mixcard").firstChild.firstChild.classList.remove("hidden");
    }
    e.target.parentElement.classList.add("open");
    setTimeout(function() {
      props.setSuperBadge(props.topicIndex)
      window.open(url, '_blank');
    }, timeOut);
  // };
}
    return(
      <>
    <div className="cell small-12 medium-4 large-4 xlarge-3 xxlarge-3 xxxlarge-3">
    <div className={[props.isCurrentTopic && props.topicChosen ? "active": "","mixcard card"].join(' ')} onClick={(e) => props.isList ? openTopic(e) : visitTopic(e, props.topicData.permalink) }>
      <div className="mixcard__image__wrap no-theme">
        <div className={["checkmark", props.topicData.isOpened ? '' : 'hidden'].join(' ')}></div>
        <div className="mixcard__image" style={topicImg} alt={props.topicData.name} />
      </div>
      <h4 className="mixcard__title">
      {props.topicData.name}
      </h4>
        <div className="mixcard__description">{props.topicData.description}</div>
      </div>
    </div>
</>
    )
};
