//#region |********\  Imports  ######################################
import React, { useState, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import Nav from './nav.js';
import PassPhrase from './00_password/pass_phrase';
import Hero from './01_hero/hero';
import Topics from './02_topics/topics';
import Chests from './04_chests/chests';
import Trails from './06_trails/trails';
import Congrats from './07_congrats/congrats';
import LegalFooter from './legal_footer/legal_footer.js';
//#endregion |********///////////////////////////////////////////////////
//#region |********\  QUEST   #######################################
export default function Quest(props) {
  //# ||****\ VARS \\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  //# |||**\ hooks -------
  const name = useFormInput('')
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentOpenedChests, setCurrentOpenedChests] = useState(0);
  const [currentOpenedTopics, setCurrentOpenedTopics] = useState(0);
  const [currentTopic, setCurrentTopic] = useState(0);
  const [topicChosen, setTopicChosen] = useState(false);
  const [currentChest, setCurrentChest] = useState(0);
  const [topicsLoading, setTopicsLoading] = useState(true);
  const [chestsLoading, setChestsLoading] = useState(true);
  const [trailsLoading, setTrailsLoading] = useState(true);
  useEffect(() => {
    if (props.passwordEntered === true && currentSlide === 0) {
    goToSlide(1);
    }
    return () => {};
  });
  let legal_footer = <LegalFooter
  consentOpen={props.consentOpen}
  setConsentOpen={props.setConsentOpen}
  />;
  let nav = <Nav
    settings={props.settings}
    nextSlide={incrementSlide}
    currentSlide={currentSlide}
    goToSlide={goToSlide}
  />;
  let askPassword = <PassPhrase
  incrementSlide={incrementSlide}
  currentSlide={currentSlide}
  goToSlide={goToSlide}
  name={name}
  passwordEntered={props.passwordEntered}
  setPasswordEntered={props.setPasswordEntered}
  />;
  let hero = <Hero
    theme={2}
    logo={props.settings.logo}
    dates={props.settings.dates}
    chests={props.settings.trailmixes}
    heroText={props.settings.hero_text}
    incrementSlide={incrementSlide}
    goToSlide={goToSlide}
    currentSlide={currentSlide}
    currentSlideList={props.currentSlideList}
  />;
  let topics = <Topics
    topics={props.settings.trail_groups}
    settings={props.settings}
    setSettings={props.setSettings}
    setTopic={setTopic}
    currentTopic={currentTopic}
    currentSlide={currentSlide}
    topicsLoading={topicsLoading}
    topicChosen={topicChosen}
    currentSlideList={props.currentSlideList}
  />;
  let chests = <Chests
    settings={props.settings}
    setSettings={props.setSettings}
    currentChest={props.settings.trailmixes[currentChest]}
    currentTopic={currentTopic}
    setChest={setChest}
    setSuperBadge={setSuperBadge}
    setTopicSuperBadge={setTopicSuperBadge}
    currentSlide={currentSlide}
    currentSlideList={props.currentSlideList}
    chestsLoading={chestsLoading}
  />;
  let trails = <Trails
    primaryColor={props.settings.primary_color}
    trailsLoading={trailsLoading}
    currentSlide={currentSlide}
    currentChest={currentChest}
    currentTopic={currentTopic}
    settings={props.settings}
    nextSlide={incrementSlide}
    goToSlide={goToSlide}
    setChestsLoading={setChestsLoading}
    setTopicsLoading={setTopicsLoading}
    currentSlideList={props.currentSlideList}
  />;
  let congrats = <Congrats
    primaryColor={props.settings.primary_color}
    settings={props.settings}
    currentChest={props.settings.trailmixes[currentChest]}
    currentSlide={currentSlide}
    goToSlide={goToSlide}
    currentSlideList={props.currentSlideList}
  />;


  //# |||**/--------------
  //# ||****/////////////////////////////////////
  //# ||****\ FUNCTIONS  \\\\\\\\\\\\\\\\\\\\\\\
  function setSuperBadge(i) {
    var tempCompany = props.settings;
    tempCompany.trailmixes[i].isOpened = true;
    props.setSettings(tempCompany);
    console.log('updated settings');
  }
  function setTopicSuperBadge(ti, i) {
    var tempCompany = props.settings;
    tempCompany.trailmixes[i].isOpened = true;
    props.setSettings(tempCompany);
    console.log('updated settings');
  }
  function setTopic(i) {
    var tempCompany = props.settings;
    tempCompany.trail_groups[i].isOpened = true;
    console.log('opening topic: '+ i);
    props.setSettings(tempCompany);
    setTopicChosen(true);
    setCurrentTopic(i);
    setTrailsLoading(true);
    if (currentOpenedTopics < 1) {
      setCurrentOpenedTopics(currentOpenedTopics + 1);
      incrementSlide();
      setTimeout(function () {
        setChestsLoading(false);
      }, 1200);
    } else {
      setChestsLoading(true);
      setCurrentSlide(props.currentSlideList.trails);
      scroll.scrollToBottom();
      setTimeout(function () {
        setChestsLoading(false);
      }, 1200);
    }
  }
  function setChest(i) {
    var tempCompany = props.settings;
    if (tempCompany.trailmixes.length >= i+1){
      tempCompany.trailmixes[i].isOpened = true;
      console.log('opening chest: '+ i);
      props.setSettings(tempCompany);
    }
    setCurrentChest(i);
    if (currentOpenedChests < 1) {
      setCurrentOpenedChests(currentOpenedChests + 1);
    }
    setTrailsLoading(true);
    setCurrentSlide(4);
    scroll.scrollToBottom();
    setTimeout(function () {
      setTrailsLoading(false);
    }, 1000);
  }

  function incrementSlide() {
    if (currentSlide === props.currentSlideList.hero) {
          // console.log('leaving hero');
      if (props.settings.trail_groups && props.settings.trail_groups.length > 0) {
        scroll.scrollToBottom();
        setTimeout(function () {
          console.log('setting topics loading to false');
          setTopicsLoading(false);
        }, 500);
      } else if (props.settings.trailmixes && props.settings.trailmixes.length > 0) {
        scroll.scrollToBottom();
        setTimeout(function () {
          setChestsLoading(false);
        }, 500);
      }
    } else if (currentSlide === props.currentSlideList.topics) {
      scroll.scrollToBottom();
      setTimeout(function () {
        setChestsLoading(false);
      }, 500);
    } else if (currentSlide === props.currentSlideList.chests) {
      scroll.scrollToBottom();
      setTimeout(function () {
        setTrailsLoading(false);
      }, 500);

    } else {
      scroll.scrollToBottom();
    }
    setCurrentSlide(currentSlide + 1);
    props.setPasswordEntered(true);
  }
  function goToSlide(e) {
    setTopicsLoading(true);
    setChestsLoading(true);
    setTrailsLoading(true);
    if (e === 1) {
      scroll.scrollToBottom();
      setTimeout(function () {
        // setChestsLoading(false);
        // setTrailsLoading(false);
      }, 500);
    } else if (e === 2) {
      if (props.settings.trail_groups && props.settings.trail_groups.length > 0) {
        scroll.scrollToBottom();
        setTimeout(function () {
          // setChestsLoading(false);
          setTopicsLoading(false);
        }, 1000);
      }else{
      scroll.scrollToBottom();
      setTimeout(function () {
        setChestsLoading(false);
        setTrailsLoading(false);
      }, 1000);
      
      }
    } else {
      setTimeout(function () {
        scroll.scrollToBottom();
      }, 0);
    }
    setCurrentSlide(e);

  }

  //# ||****////////////////////////////////
  //# ||****\ RENDER \\\\\\\\\\\\\\\\\\\\\\\\\\\
  return (
    <div className="quest">
      {nav}
      {askPassword}
      {hero}
      {topics}
      {chests}
      {trails} 
      {congrats}
      {legal_footer}
    </div>
  );
  //# ||****///////////////////////////////////////////////////
}
//#endregion |********/#################################################
//#region |********\  Hook Functions (useFormInput)#####################
function useFormInput(initialValue) {
  const [value, setValue] = useState(initialValue);
  function handleChange(e) {
    setValue(e.target.value)
  }
  return {
    value,
    onChange: handleChange
  };
}
//#endregion |********///////////////////////////////////////////////////