import React from 'react';
import './hero.scss'

export default function Hero(props) {
  const chestBg = "images/01_hero/hero-background-3.png"
  const astroImg = "images/01_hero/hero-character-4.png"
  const headingImg = "images/01_hero/heading-3.svg";
  const sectionBgStyle = { backgroundImage: 'url(' + chestBg + ')' };
  const corpColorBgStyle = { backgroundColor: props.primaryColor };
  const logoStyle = { backgroundImage: 'url('+ props.logo + ')' };
  return (
    <section id="hero-section" className={[props.currentSlide > 0 ? 'hero section section-bg-1 text-center visible' : 'hero section section-bg-1 white-text text-center hidden', props.currentSlide === 0 ? 'active' : ''].join(' ')} style={sectionBgStyle}>
      <div className="grid-container hero-container">
        <div className="grid-x align-center align-middle full-height pt60 pb80">
          <div className="cell">
            <div className="section-container section-gap-3">

              <div className="section-description hero-heading">
                <img className="hero-heading-img" src={headingImg} alt="Join the Trailblazer Challenge" />
              </div>
              <div className="section-description">
                <span className="bold margin-bottom-15">What is a Trailblazer?</span><br />A pioneer. An innovator. A lifelong learner. A mover and a shaker<br/><br/>
                Join the Trailblazer Challenge to skill up for the future while you're earning badges, ranks,<br/> and a chance to win prizes.
              </div>
              <div className="hero-button">
                <button style={corpColorBgStyle} type="button" className={props.chests.length > 0 ? '' : 'hidden'} onClick={() => props.goToSlide(2)}>Let&rsquo;s go!</button>
                <div className="dates">{props.dates}</div>
                <div className="company-logo" style={logoStyle} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-character">
        <img src={astroImg} alt="Astro" width="330" height="400" />
      </div>
    </section>
  )
};
