import React from 'react';
// import parse from 'html-react-parser';
export default function Prize(props) {
    const prizeImage = { backgroundImage: 'url(' + props.image + ')' };
    const topStripe = { borderTopColor: props.color };
    return (
        <div className="cell small-11 medium-8 large-5 xlarge-4">
            <div className="rewards-prize--item" style={topStripe}>
                <div className="rewards-prize--image" style={prizeImage}>
                    <img src={props.photo} alt={props.name} />
                </div>
                <h3 className="rewards-prize--title">{props.header}</h3>
                <h4 className="rewards-prize--subtitle">
                    {props.subtitle}</h4>
                <div className="rewards-prize--info i1">
                    <ul>
                        <li>{props.altEmea === '' ? props.description : props.description+'*'}</li>
                    </ul>
                    {props.option1_name && props.option1_name !== '' && props.option1_link && props.option1_link !== '' ? <span className="option"><a href={props.option1_link} target="_blank" rel="noopener noreferrer">{props.option1_name}</a></span> : ''}
                    {props.option2_name && props.option2_name !== '' && props.option2_link && props.option2_link !== '' ? <span className='or'>&nbsp;or&nbsp;</span> : ''}
                    {props.option2_name && props.option2_name !== '' && props.option2_link && props.option2_link !== '' ? <span className="option"><a href={props.option2_link} target="_blank" rel="noopener noreferrer">{props.option2_name}</a></span> : ''}
                </div>
                <div className="price">{props.price}</div>
                    {/* <a className="button margin-top-15 btn-blue" onClick={() => props.showForm(props.claimLink)} target="_blank">Claim Prize</a> */}
                    <div className="footnote">{props.altEmea === '' ? '' : '*Alternative for EMEA: '+props.altEmea}</div>
                
            </div>
        </div>
    )
}
