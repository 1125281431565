import './community_page.scss'
import React, { useEffect } from 'react';
import Equalizer from 'react-equalizer';
import Nav from '../nav.js';
// import ReactGA from 'react-ga';
import uuid from 'react-uuid';
import ResourceCard from './resource_card';
import LegalFooter from '../legal_footer/legal_footer';
// import { useSpring, animated } from 'react-spring'

export default function RewardsPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {
    };
  }, []);
  const currentTheme = 2;
  const topBg = "images/community_page/connect-bg-top-2.png";
  const botBg = "images/community_page/connect-bg-bottom-2.png";
  // const midBg = "images/community_page/connect-bg-middle-2.png"
  const sectionTopBgStyle = { backgroundImage: 'url(' + topBg + ')' };
  const sectionBotBgStyle = { backgroundImage: 'url(' + botBg + ')' };

  // ReactGA.event({
  //   category: 'Team',
  //   action: 'page visited'
  // });
  return (
    <>
      <Nav settings={props.settings} />
      <section id="community-page" className={["material full-height section section-gap-1 section-bg-g1 text-center", currentTheme === 1 ? "section-bg-g1" : "section-bg-white"].join(' ')} >
        <div className="background-image-top" style={sectionTopBgStyle}></div>
        <div className="background-image-bottom"  style={sectionBotBgStyle}></div> 
        <div className="container">
          <div className="grid-container" >
            <div className="grid-x">
              <div className="cell">
                <div className="section-container relative">
                  <h1 className="section-title">Connect with Trailblazers.</h1>
                  {/* <h2 className="section-subtitle">Explore relevant community to help you succeed on your Trailhead Journey.</h2> */}
                  <div className="section-description">Join your peers blazing new trails with Salesforce. Expand your professional skills, ask questions on key topics, collaborate, network and get inspired!</div>
                </div>
              </div>
            </div>
          </div>
          <div className="material-card--container relative">
            <div className="grid-container">
              <Equalizer property="height" className="grid-x align-center align-top">
                {Object.keys(props.settings.community).map((t) => {
                  return <ResourceCard
                    key={uuid()}
                    cardTitle={t}
                    name={props.settings.community[t].name}
                    subtitle={props.settings.community[t].description}
                    permalink={props.settings.community[t].permalink}
                    image={props.settings.community[t].image}
                  />
                })}            
              </Equalizer>
            </div>
          </div>
          <br/>
          <br/>
          <br/>
        </div>
        <br/>
        <br/>
          <LegalFooter
  consentOpen={props.consentOpen}
  setConsentOpen={props.setConsentOpen}
  />
      </section>
    </>
  )
};
