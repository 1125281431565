import React from 'react';
import Topic from './topic.js';
import uuid from 'react-uuid';
import Loading from '../loading/loading_icon.js';
// import Equalizer from 'react-equalizer';
const topicsBgTop = "images/04_chests/chests-background-top.png";
const greyBgTop = "images/04_chests/chests-background-top-2.png";
const topicsBgBottom = "images/04_chests/chests-background-bottom.png";
const greyBgBottom = "images/04_chests/chests-background-bottom-2.png";
export default function TopicList(props) {
  var sectionBgTopStyle = { backgroundImage: 'url(' + topicsBgTop + ')' };
  var sectionBgBottomStyle = { backgroundImage: 'url(' + topicsBgBottom + ')' };

    sectionBgTopStyle = { backgroundImage: 'url(' + greyBgTop + ')' };
    sectionBgBottomStyle = { backgroundImage: 'url(' + greyBgBottom + ')' };

  if (props.topicsLoading === true) {
    return <Loading />
  } else {
    return (
      <div className="topics-card--container">
        <div className="topics-card--background top" >
          <img src={topicsBgTop} alt="" width="3840" height="740" />
        </div>
        <div className="topics-card--list">
          <div className="grid-container">
            <div className="grid-x align-center">
              {/* {Object.keys(props.topics).map((t) => (
                // filter out topics that are not unlocked
                props.topics[t].isOpened === true ?
                  <Topic
                    key={uuid()}
                    setTopic={props.setTopic}
                    settings={props.settings}
                    topicData={props.topics[t]}
                    topicIndex={t}
                  /> : ''
              ))} */}
            
              {Object.keys(props.topics).map((t) => (
                <Topic
                  key={uuid()}
                  setTopic={props.setTopic}
                  settings={props.settings}
                  topicData={props.topics[t]}
                  topicIndex={t}
                  isList = {true}
                  isCurrentTopic={t == props.currentTopic}
                  topicChosen={props.topicChosen}
                  />
              ))}
            </div>
          </div>
        </div>
        <div className="topics-card--background bottom" >
          <img src={topicsBgBottom} alt="" width="3840" height="585" />
        </div>
      </div>
    )
  }
};
