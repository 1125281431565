import './team_page.scss'
import React, { useEffect } from 'react';
import Equalizer from 'react-equalizer';
import Nav from '../nav.js';
// import ReactGA from 'react-ga';
import uuid from 'react-uuid';
import MemberCard from './member_card'
import ResourceCard from './resource_card'
import LegalFooter from '../legal_footer/legal_footer';
export default function RewardsPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {
    };
  }, []);
    const currentTheme = 2;
    const topBg = "images/team_page/contact-bg-top-2.png";
    const botBg = "images/team_page/contact-bg-bottom-2.png";

  const sectionTopBgStyle = { backgroundImage: 'url(' + topBg + ')' };
  const sectionBotBgStyle = { backgroundImage: 'url(' + botBg + ')' };

  return (
    <>
      <Nav settings={props.settings} />
      <section id="team" className={["material team section full-height text-center section-gap-1 relative",currentTheme === 1 ? "section-bg-gi" : "section-bg-0"].join(' ')}>
        <div className="background-image-top" style={sectionTopBgStyle}></div>
        <div className="background-image-bottom" style={sectionBotBgStyle}></div>
        <div className="container relative">
          <div className="cloud4"></div>
          <div className="cloud5"></div>
          <div className="cloud6"></div>
          <div className="section-container dark">
                  <h1 className="section-title">We’re with you every step of the journey.</h1>
                  {/* <h2 className="section-subtitle">Explore relevant resources to help you succeed on your Trailhead Journey.</h2> */}
                  <div className={["section-subtitle", currentTheme === 1 ? "white-text" : ""]}>
                  Explore your Trailhead support resources.</div>
                  <div className="material-card--container">
                    <div className="grid-container mb-80">
                      <Equalizer property="height" className="grid-x align-center align-top">
                        {Object.keys(props.settings.support).map((t) => {
                            return <ResourceCard
                              key={uuid()}
                              cardTitle={t}
                              name={props.settings.support[t].name}
                              subtitle={props.settings.support[t].description}
                              permalink={props.settings.support[t].permalink}
                              image={props.settings.support[t].image}
                            />
                        })}
                      </Equalizer>
                    </div>
                  </div>
                </div>
          <div className="grid-container relative">
            <div className="grid-x">
              <div className="cell">
                <div className="section-container">
                  {/* <h1 className="section-title">We’re with you every step of the journey.</h1> */}
                  {/* <h2 className="section-subtitle">Meet the team rooting for your success as you skill up for the future.</h2> */}
                  {/* <div className="section-description">Explore your Trailhead support resources.</div> */}
                </div>
              </div>
            </div>
          </div>
          <h1 className={props.settings.members.count > 0 ? "text-center" : "hidden"}>Success Team</h1>
          <div className="team-card--container pb-80 relative">
            <div className="grid-container">
              <div className="grid-x align-center align-stretch">
              {Object.keys(props.settings.members).map((t) => {
                            return  <MemberCard
    key={uuid()}
    cardTitle={props.settings.members[t].name}
    name={props.settings.members[t].name}
    title={props.settings.members[t].job_title}
    photo={props.settings.members[t].photo}
    linkedin={props.settings.members[t].contact}
    company={props.settings.members[t].company}
    />})}
              </div>
            </div>
          </div>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <LegalFooter
  consentOpen={props.consentOpen}
  setConsentOpen={props.setConsentOpen}
  />
      </section>
    </>
  )
};
