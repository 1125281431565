import React from 'react';
export default function MemberCard(props) {
  var cardBg = props.photo ? props.photo : "../../images/noavatar.png";
  const cardBgStyle = { backgroundImage: 'url('+ cardBg +')' }; 
  return (
    <div className="cell small-11 medium-8 large-4">
      <div className="team-card--item">
        <div className="team-card--image" style={cardBgStyle}>
          <img src={props.photo} alt={props.name} />
        </div>
        <div className="team-card--body">
          <h3 className="team-card--title">{props.name}</h3>
          <div className="team-card--info">{props.title}<br />{props.company}</div>
          
          <div className={[ props.linkedin && props.linkedin !== '' ? "" : "hidden", "team-card--button"].join(' ')}>
            <a className="btn-blue" href={"mailto:" + props.linkedin} target="_blank" rel="noopener noreferrer">Email</a>
          </div>
        </div>
      </div>
    </div>
  )
}
