import React from 'react';
import './legal_footer.scss'
export default function LegalFooter(props) {
  return (
    <div className="legal-footer-wrap">
      {/* <div className={props.consentOpen ? 'consent' : 'consent invisible'}>
        Our Website uses cookies to provide necessary website functionality, improve your experience and analyze our traffic. By using our website, you agree to our Privacy Policy and our cookies usage. &nbsp;
        <div className="button btn-blue no-margin" onClick={() => props.setConsentOpen(false)}>Ok</div>
      </div> */}
      <br/>
      <div className="legal-footer">
        <div className="copy-note">
          © Copyright 2023 Salesforce.com, inc. All rights reserved. Various trademarks held by their respective owners. Salesforce.com, inc. Salesforce Tower, 415 Mission Street, 3rd Floor, San Francisco, CA 94105,
        </div>
        <ul className="page-footer_links_list">
          <li className="page-footer_links_item">
            <a className="page-footer_link " href="https://www.salesforce.com/company/legal/">Legal</a>
          </li>
          <li className="page-footer_links_item">
            <a className="page-footer_link " href="https://www.salesforce.com/company/legal/sfdc-website-terms-of-service/">Terms of Service</a>
          </li>
          <li className="page-footer_links_item">
            <a className="page-footer_link " href="https://www.salesforce.com/company/privacy/">Privacy Information</a>
          </li>
          <li className="page-footer_links_item">
            <a className="page-footer_link " href="https://www.salesforce.com/company/disclosure/">Responsible Disclosure</a>
          </li>
          <li className="page-footer_links_item">
            <a className="page-footer_link " href="https://trust.salesforce.com/en/" target="_blank" rel="noopener noreferrer">Trust</a>
          </li>
          <li className="page-footer_links_item">
            <a className="page-footer_link " href="https://www.salesforce.com/company/contact-us/">Contact</a>
          </li>
          {/* <li className="page-footer_links_item">
                     <a className="page-footer_link optanon-toggle-display" href="#" data-ignore-geolocation="true">Cookie Preferences</a>
                  </li> */}
        </ul>
      </div>
    </div>
  )

};
