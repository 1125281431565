import React from 'react';
import Chest from './chest.js';
import uuid from 'react-uuid';
import Loading from '../loading/loading_icon';
// import Equalizer from 'react-equalizer';
const chestsBgTop = "images/04_chests/chests-background-top.png";
const greyBgTop = "images/04_chests/chests-background-top-2.png";
const chestsBgBottom = "images/04_chests/chests-background-bottom.png";
const greyBgBottom = "images/04_chests/chests-background-bottom-2.png";
export default function ChestList(props) {
  var sectionBgTopStyle = { backgroundImage: 'url(' + chestsBgTop + ')' };
  var sectionBgBottomStyle = { backgroundImage: 'url(' + chestsBgBottom + ')' };
  // var theme = 1;

    sectionBgTopStyle = { backgroundImage: 'url(' + greyBgTop + ')' };
    sectionBgBottomStyle = { backgroundImage: 'url(' + greyBgBottom + ')' };

  if (props.chestsLoading === true) {
    return <Loading />
  } else {
    return (
      <div className={["chests-card--container", 'grey-bg' ].join(' ')}>
        <div className={["chests-card--background top", 'grey-bg' ].join(' ')} style={sectionBgTopStyle}>
          <img src={chestsBgTop} alt="" width="3840" height="740" />
        </div>
        <div className="chests-card--list">
          <div className="grid-container">
            <div className="grid-x align-center">
              {/* {Object.keys(props.chests).map((t) => (
                // filter out chests that are not unlocked
                props.chests[t].isOpened === true ?
                  <Chest
                    key={uuid()}
                    setChest={props.setChest}
                    settings={props.settings}
                    chestData={props.chests[t]}
                    chestIndex={t}
                  /> : ''
              ))} */}
            
              {Object.keys(props.chests).map((t) => (
                // filter out chests that are not unlocked
                props.chests[t].isSuperbadge === false ?
                <Chest
                  key={uuid()}
                  setChest={props.setChest}
                  setSuperBadge={props.setSuperBadge}
                  settings={props.settings}
                  chestData={props.chests[t]}
                  chestIndex={t}
                  isList = {true}
                  /> : ''
              ))}
            </div>

              {
                Object.keys(props.chests).filter((t) => props.chests[t].isSuperbadge === true).length > 0 ?
                <h4 className="h2 align-center" >Superbadges</h4> : ''
              }
              {
                Object.keys(props.chests).filter((t) => props.chests[t].isSuperbadge === true).length > 0 ?
                <p className="bold align-center" >Credentials that let you take the skills you’ve learned and apply them to complex, real-world business problems.</p> : ''
              }
            <div className="grid-x align-center">
              {Object.keys(props.chests).map((t) => (
                // filter out chests that are not unlocked
                props.chests[t].isSuperbadge === true ?
                <Chest
                  key={uuid()}
                  setChest={props.setChest}
                  setSuperBadge={props.setSuperBadge}
                  settings={props.settings}
                  chestData={props.chests[t]}
                  chestIndex={t}
                  isList = {false}
                  /> : ''
              ))}
              
            </div>
          </div>
        </div>
        <div className="chests-card--background bottom" style={sectionBgBottomStyle}>
          <img src={chestsBgBottom} alt="" width="3840" height="585" />
        </div>
      </div>
    )
  }
};
