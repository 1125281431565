import './nextsteps_page.scss'
import React, { useEffect } from 'react';
import Equalizer from 'react-equalizer';
import Nav from '../nav.js';
// import ReactGA from 'react-ga';
import uuid from 'react-uuid';
import nextStepsData from '../../config/nextsteps';
import NextStepsCard from './nextsteps_card';
import LegalFooter from '../legal_footer/legal_footer';
export default function NextStepsPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {
    };
  }, []);
  const  currentTheme = 2;
  const  heroTopBg = "images/nextsteps_page/inspired-top-2.png"
  const  heroBotBg = "images/nextsteps_page/inspired-bottom-2.png"
  const sectionTopBgStyle = { backgroundImage: 'url(' + heroTopBg + ')' };
  const sectionBotBgStyle = { backgroundImage: 'url(' + heroBotBg + ')' };

  // ReactGA.event({
  //   category: 'Team',
  //   action: 'page visited'
  // });
  return (
    <>
      <Nav settings={props.settings} />
      <section id="sf_plus-page" className={["material section section-gap-1 section-bg-gi full-height text-center", currentTheme === 1 ? 'darkbg' : 'white-bg'].join(' ')} >
        <div className="background-image-top" style={sectionTopBgStyle}></div>
        <div className="background-image-bottom" style={sectionBotBgStyle}></div>
        <div className="container" >
          <div className="grid-container relative">
            <div className="grid-x">
              <div className="cell">
                <div className="section-container dark">
                  <h1 className={["section-title", currentTheme === 1 ? "white-text" : ""]}>Trailblazer Challenge Next Steps</h1>
                  <h2 className="section-subtitle">Keep on Learning</h2>
                  <br/>
                  <div className={["section-subtitle", currentTheme === 1 ? "white-text" : ""]}>
                  Continue your learning journey on Trailhead and beyond! When you’re ready to deep dive into Salesforce, check out our expert-led classes, bootcamps, and certifications.</div>
                  <div className="material-card--container">
                    <div className="grid-container">
                      <Equalizer property="height" className="grid-x align-center align-top">
                        {Object.keys(nextStepsData.items).map((t) => {
                            return <NextStepsCard
                              key={uuid()}
                              cardTitle={t}
                              name={nextStepsData.items[t].name}
                              subtitle={nextStepsData.items[t].description}
                              permalink={nextStepsData.items[t].permalink}
                              image={nextStepsData.items[t].image}
                              ctaText={nextStepsData.items[t].ctaText}
                            />
                        })}
                      </Equalizer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LegalFooter
  consentOpen={props.consentOpen}
  setConsentOpen={props.setConsentOpen}
  />
      </section>
    </>
  )
};
