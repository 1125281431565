import React from 'react';
import './chests.scss';
// import './chests_page.scss';
import ChestList from './chest_list.js'
export default function Chests(props) {

  // const corpColorStyle = { color: props.primaryColor };
  let chests = [];
  if (props.settings.trail_groups && props.settings.trail_groups.length > 0) {
    if (props.currentTopic){
      chests = props.settings.trail_groups[props.currentTopic].trailmixes
    } else {
      chests = props.settings.trail_groups[0].trailmixes
    }
  } else if (props.settings.trailmixes && props.settings.trailmixes.length > 0) {
    chests = props.settings.trailmixes
  }
  if (props.settings.trail_groups && props.settings.trail_groups.length > 0) {
    return (
      <section id="chests-section" className={[props.currentSlide > props.currentSlideList.chests ? 'visible' : 'hidden', props.currentSlide === props.currentSlideList.chests ? 'active' : '', 'chests section section-bg-2 text-center'].join(' ')}>
        <div className="grid-container fluid">
          <div className="grid-x align-center align-top full-height">
            <div className="cell">
              <div className="section-container section-gap-2">
                <div className="grid-container relative z-index-1">
                  <div className="grid-x align-center">
                    <div className="cell small-12 medium-7 large-8 chest-cell" >
                       <div className="section-tag">02/03</div>
                      {/*<h2 className="section-title">Which skills would <em>you</em> like to unlock?</h2>
                      <h3 className="section-subtitle chests-section-subtitle text-glow">Explore skills that align with your role<br/> or challenge yourself outside of your areas of expertise.</h3> */}
                    </div>
                  </div>
                </div>
                <ChestList
                  chests={chests}
                  chestsLoading={props.chestsLoading}
                  currentChest={props.currentChest}
                  setChest={props.setChest}
                  setSuperBadge={props.setSuperBadge}
                  setTopicSuperBadge={props.setTopicSuperBadge}
                  settings={props.settings}
                >
                </ChestList>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }else{ 
    return (
    <section id="chests-section" className={[props.currentSlide >= props.currentSlideList.chests ? 'visible' : 'hidden', props.currentSlide === props.currentSlideList.chests ? 'active' : '', 'chests section section-bg-2 text-center'].join(' ')}>
      <div className="grid-container fluid">
        <div className="grid-x align-center align-top full-height">
          <div className="cell">
            <div className="section-container section-gap-2">
              <div className="grid-container relative z-index-1">
                <div className="grid-x align-center">
                  <div className="cell small-12 medium-7 large-8 chest-cell" >
                    <div className="section-tag">02/03</div>
                    <h2 className="section-title">Which skills would <em>you</em> like to unlock?</h2>
                    <h3 className="section-subtitle chests-section-subtitle text-glow">Explore skills that align with your role<br/> or challenge yourself outside of your areas of expertise.</h3>
                  </div>
                </div>
              </div>
              <ChestList
                chests={chests}
                chestsLoading={false}
                currentChest={props.currentChest}
                setChest={props.setChest}
                setSuperBadge={props.setSuperBadge}
                settings={props.settings}
              >
              </ChestList>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
};
